import request from '@/utils/request'

export function fetchDashboard(typ, query) {
  return request({
    url: '/v1/global-trends/dashboard/' + typ,
    method: 'get',
    params: query
  })
}

export function fetchCompare(typ, query) {
  return request({
    url: '/v1/global-trends/compare/' + typ,
    method: 'get',
    params: query
  })
}

export function fetchDetails(typ, query) {
  return request({
    url: '/v1/global-trends/data/' + typ,
    method: 'get',
    params: query
  })
}

export function fetchOptionsYear(typ) {
  return request({
    url: '/v1/global-trends/options/' + typ + '/year',
    method: 'get',
  })
}

export function fetchOptionsSeason(typ, query) {
  return request({
    url: '/v1/global-trends/options/' + typ + '/season',
    method: 'get',
    params: query
  })
}

export function fetchOptionsItem(typ, query) {
  return request({
    url: '/v1/global-trends/options/' + typ + '/item',
    method: 'get',
    params: query
  })
}

export function fetchOptionsValue(typ, query) {
  return request({
    url: '/v1/global-trends/options/' + typ + '/value',
    method: 'get',
    params: query
  })
}