<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <slot name="addition"></slot>
    <el-pagination
      :small="small"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :pager-count.sync="pagerCount"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to";

export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    small: {
      require: false,
      type: Boolean,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.offset / this.limit + 1;
      },
      set(val) {
        this.$emit("update:offset", (val - 1) * this.limit);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  padding: 10px 16px;
  margin: 10px 0;
  text-align: right;
  /deep/ .el-pagination__total {
    font-weight: bold;
    margin-left: 10px;
  }
  /deep/ .el-input__inner {
    font-weight: bold;
  }
  .el-pagination {
    display: inline-block;
  }
}
.pagination-container.hidden {
  display: none;
}
</style>
