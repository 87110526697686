<template>
  <div class="global-trends-search">
    <ai-breadcrumb />
    <div class="new-button">
      <guide class="guide-button" :disabled="true" />
    </div>
    <el-alert :title="$t('globalTrend.noticeWarning')" type="warning" show-icon class="alert-content">
    </el-alert>
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.year')">
          <el-select style="width: 88px" v-model="currentYear" @change="changeYear" filterable>
            <el-option v-for="item in yearList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.season')">
          <el-select style="width: 88px" v-model="currentSeason" @change="changeSeason">
            <el-option v-for="item in seasonList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.sex')">
          <el-select style="width: 88px" v-model="currentSex" clearable>
            <el-option v-for="item in sexList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.category')">
          <el-select style="width: 112px" v-model="currentCategory" clearable @change="changeCategory">
            <el-option v-for="item in categoryList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.item')">
          <el-select style="width: 168px" v-model="currentItem" clearable @change="changeItem">
            <el-option v-for="item in itemList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('globalTrend.' + type)">
          <el-select style="width: 168px" v-model="currentValue" clearable>
            <el-option v-for="item in valueList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" circle @click="fetchData"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-content">
      <p v-if="loading" class="data-loading">
        <i class="el-icon-loading" />&nbsp;{{ $t("common.loading") }}...
      </p>
      <p v-else-if="!tableData || tableData.length === 0" class="data-loading">
        {{ $t("common.noMoreData") }}
      </p>
      <el-row v-else :gutter="10">
        <el-col class="data-block" :lg="{ span: '5-24' }" v-for="item in tableData" :key="item.id">
          <div class="data-block-content">
            <el-image class="content-image" :src="item.imageUrl" :preview-src-list="[item.imageUrl]" fit="contain" />
            <div class="data-block-text">
              <el-row class="content-line">
                <span class="content-label">{{ "" }}</span>
                # {{ item.year }} # {{ item.season }} # {{ item.sex }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{ "" }}</span>
                # {{ item.category }} # {{ item.itemType }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{ "" }}</span>
                # {{ item.value }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{ "" }}</span>
                # {{ item.brand }}
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <pagination v-show="total > 0 && !loading" :total="total" :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit" layout="sizes, prev, pager, next, total " @pagination="fetchData" />
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
import Pagination from "@/components/Pagination";
import {
  fetchDetails,
  fetchOptionsYear,
  fetchOptionsSeason,
  fetchOptionsItem,
  fetchOptionsValue,
} from "@/api/global-trend";

export default {
  components: {
    AiBreadcrumb,
    Guide,
    Pagination,
  },
  data() {
    return {
      tableData: [],
      yearList: [],
      seasonList: [],
      sexList: ["W", "M"],
      categoryList: ["TOP", "OUTER", "BOTTOM", "DRESS"],
      itemList: [],
      valueList: [],
      currentYear: "",
      currentSeason: "",
      currentSex: "W",
      currentCategory: "DRESS",
      currentItem: "",
      currentTrendPoint: "",
      currentValue: "",
      loading: false,
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
    }
  },
  mounted() {
    this.fetchOptionsYear(() => {
      this.fetchData();
    });
  },
  methods: {
    fetchOptionsYear(callback) {
      fetchOptionsYear(this.type).then(response => {
        if (response.success) {
          this.yearList = response.result.items || [];
          if (this.yearList.length > 0) {
            this.currentYear = this.yearList[0];
            this.fetchOptionsSeason(callback);
          }
        }
      })
    },
    fetchOptionsSeason(callback) {
      this.currentSeason = "";
      fetchOptionsSeason(this.type, { year: this.currentYear }).then(response => {
        if (response.success) {
          this.seasonList = response.result.items || [];
          if (this.seasonList.length > 0) {
            this.currentSeason = this.seasonList[0];
            this.fetchOptionsItem(callback);
          }
        }
      })
    },
    fetchOptionsItem(callback) {
      this.currentItem = "";
      fetchOptionsItem(this.type, {
        category: this.currentCategory,
        years: this.currentYear, seasons: this.currentSeason,
      }).then(response => {
        if (response.success) {
          this.itemList = response.result.items || [];
          if (this.itemList.includes("DRESS")) {
            this.currentItem = "DRESS";
          } else if (this.itemList.length > 0) {
            this.currentItem = this.itemList[0];
          }
          this.fetchOptionsValue(callback);
        }
      })
    },
    fetchOptionsValue(callback) {
      this.currentValue = "";
      fetchOptionsValue(this.type, {
        category: this.currentCategory,
        year: this.currentYear,
        season: this.currentSeason,
        item: this.currentItem,
      }).then(response => {
        if (response.success) {
          this.valueList = response.result.items || [];
          if (this.valueList.length > 0) {
            this.currentValue = this.valueList[0];
          }
          if (callback) {
            callback();
          }
        }
      })
    },
    fetchData() {
      this.tableData = [];
      this.loading = true;
      fetchDetails(this.type, {
        year: this.currentYear,
        season: this.currentSeason,
        sex: this.currentSex,
        category: this.currentCategory,
        item: this.currentItem,
        value: this.currentValue,
        ...this.listQuery,
      })
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items ? response.result.items : [];
            this.total = response.result.totalCount;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeYear() {
      this.fetchOptionsSeason();
    },
    changeSeason() {
      this.fetchOptionsItem();
    },
    changeCategory() {
      this.fetchOptionsItem();
    },
    changeItem() {
      this.fetchOptionsValue();
    },
    clickDownload() { }
  },
  computed: {
    type() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["type"];
    },
  }
};
</script>

<style lang="scss" scoped>
.global-trends-search {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 20px;
      }
    }
  }

  .data-content {
    overflow: hidden;
    font-size: small;
    margin-bottom: -10px;

    .data-loading {
      text-align: center;
      font-size: 14px;
      color: #606266;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .data-block {
      margin-bottom: 10px;

      .data-block-content {
        background-color: #fff;
        padding-bottom: 5px;

        .content-image {
          width: 100%;
          height: 0;
          padding-bottom: 100%;

          /deep/ img {
            position: absolute;
            margin-bottom: 5px;
          }
        }

        .data-block-text {
          margin-top: 5px;
          text-align: center;
        }

        .content-line {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);

          .content-label {
            color: #a9a9a9;
            margin-right: 5px;
            line-height: 27px;
          }

          .content-link {
            margin-top: 5px;
            margin-right: 5px;
            line-height: 27px;
          }
        }
      }
    }
  }

  .alert-content {
    margin-bottom: 10px;
  }
}
</style>